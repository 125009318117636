export const fetchLocation = async () => {
  let location: any;
  try {
    location = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
      localStorage.setItem("user-Agent", navigator.userAgent);
      localStorage.setItem(
        "devicetype",
        navigator.userAgent.match(/Android/i)
          ? "android"
          : navigator.userAgent.match(/mac/i)
          ? "macbook"
          : "windows"
      );
    });
  } catch (err) {
    return err;
  }
  fetch("https://api.ipify.org?format=json")
    .then((response) => response.json())
    .then((data) => {
      localStorage.setItem("ip", data.ip);
    });
  localStorage.setItem("lat", location.coords.latitude);
  localStorage.setItem("long", location.coords.longitude);
  return location;
};
